import { useEffect, useState } from "react";

export default function useGetData(login, heslo) {

    const [data, setData] = useState(undefined);
    
    async function getData(login, heslo) {

        try {
            const response = await fetch(`https://getdocumentbyemailandheslo-vgjwhs6wxq-uc.a.run.app/?login=${login}&heslo=${heslo}`);
    
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
    
            const data = await response.json();

            setData(data)

        } catch (error) {
            console.error('Error searching for email:', error);
            setData(false)
        }
    }


    useEffect(() => {
        if(login && heslo) {
            getData(login, heslo)
        }
    }, [login, heslo])

    return data
}