
import { useContext, useEffect, useRef, useState } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// PICTURES
import BackroundPic from "../pictures/landingPage.webp";
import audioOffImg from "../pictures/icons/volumeoff.svg";
import audioOnImg from "../pictures/icons/volumeon.svg";

// VIDEO
import backgroundVideo from "../video/video.mov"

// ROUTER
import { useNavigate } from 'react-router-dom';

// HELPING FUNCTIONS
import { loadAllImages, getHeight } from "../functions/HelpingFunctions";

// COMPONENTS
import LoginLandingPage from "../components/LoginLandingPage";



export default function LandingPage() {

    // ROUTER
    const navigate = useNavigate()

    // GLOBAL STATES
    const {logedIn} = useContext(DataContext);

    // REFS
    const videoRef = useRef();

    // STATES
    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [BackroundPic];
    const [videoMuted, setVideoMuted] = useState({
        state: true,
        img: audioOffImg
    });

    // SCALING
    const [scale, setScale] = useState(1);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale()
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])


    // ON LOAD
    useEffect(() => {
        //WAIT TO LOAD ALL IMAGES
        async function loadImages() {
            try{
                const loadingState =  await loadAllImages(imageUrls);
                setAllImagesLoaded(loadingState);
            }
            catch(err) {
                console.log(err)
            }
        }

        loadImages()

        if(logedIn.loggedIn === true) {
            navigate("/client/" + logedIn.login)
        }
    }, []);

    // PLAY AFTER LOAD
    useEffect(() => {
        const video = videoRef.current;

        if(allImagesLoaded != "0") {
            if (video) {
                video.play().catch(error => {
                  console.error('Error attempting to play the video:', error);
                });
              }
        }
    }, [allImagesLoaded])

    return(
        <>
        <div 
        style={{
            opacity: allImagesLoaded,
            transition: "1s",

        }}
        >
            <div className="flex-col align-center">  
                <div
                className="background-pic flex-col align-center"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>
                    <img width={2311.5} height={1300} src={BackroundPic}></img>
                </div>


                <div 
                className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                    //background: "orange"
                }}
                >
                    <video 
                    ref={videoRef} 
                    width="820px"
                    height="auto"
                    //autoPlay={true} 
                    loop={true}
                    controls={false} 
                    playsInline
                    muted
                    src={backgroundVideo} 
                    style={{
                        transform: "translate(3px, 26px)",
                        position: "absolute", 
                    }}
                    type="video/mov" 
                    >
                    </video>

                    <button
                    style={{
                        transform: "translate(380px, -170px)",
                        position: "absolute", 
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        opacity: "0.8"
                    }}
                    onClick={() => {
                        // UNMUTE / MUTE AUDIO
                        const thisState = !videoMuted.state;

                        if(thisState === false) {
                            setVideoMuted({
                                state: thisState,
                                img: audioOnImg
                            });
                        } else{
                            setVideoMuted({
                                state: thisState,
                                img: audioOffImg
                            });
                        }

                        
                        videoRef.current.muted = thisState;
                    }}
                    >
                        <img width="25" height="auto" src={videoMuted.img}/>
                    </button>



                    <LoginLandingPage scale={scale}/>    
                </div>           
            </div>
        </div>
        </>
    )
}