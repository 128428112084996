
import { useEffect, useState } from "react";

// ROUTER
import { Link, useParams } from 'react-router-dom';

// PICTURES
import BackroundPic from "../pictures/uvod2.webp";
import Listapic from "../pictures/lista.png";

// HELPING FUNCTIONS
import { loadAllImages, getHeight } from "../functions/HelpingFunctions";

// COMPONENTS
import LogOutBtn from "./LogOutBtn";


export default function MenuV2({ thisData }) {

    // ROUTER
    const params = useParams()

    // STATES
    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [BackroundPic];


    // SCALING
    const [scale, setScale] = useState(1);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale();
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])



    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {
        async function loadImages() {
            try{
                const loadingState =  await loadAllImages(imageUrls);
                setAllImagesLoaded(loadingState);
            }
            catch(err) {
                console.log(err)
            }
        }
        loadImages()
        
        // eslint-disable-next-line
    }, []);



    return(
        <>
        <div 
        style={{
            opacity: allImagesLoaded,
            transition: "1s",
        }}
        >
            
                        
            <div className="flex-col align-center">
                <div 
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}
                className="background-pic flex-col align-center">
                    <img width={2311.5} height={1300} src={BackroundPic}></img>
                </div>


                <div className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>
                        <LogOutBtn />

                        <div
                        style={{
                            transform: "translate(-550px, 420px)",
                            position: "absolute", 
                            
                        }}
                        >
                            <img style={{scale: "0.9"}} src={Listapic}></img>
                        </div>

                        <div
                        style={{
                            transform: "translate(-552px, 418px)",
                            position: "absolute", 
                            width: "500px",
                            textAlign: "center"
                        }}
                        >
                            <span className="menu-text-2">*{thisData?.nazev}</span>
                        </div>
                        
                        <div className="menu-2-container" >
                            
                            <Link 
                            to={"/client/" + params.id + "/locations"}
                            className="menu-text-container-2"
                            >
                                <span className="menu-text-2-main">LOCATIONS</span>
                            </Link>

                            <Link 
                            to={"/client/" + params.id + "/production-design"}
                            className="menu-text-container-2"
                            >
                                <span className="menu-text-2-main">PROD. DESIGN</span>
                            </Link>

                            <Link 
                            to={"/client/" + params.id + "/wardrobe"}
                            className="menu-text-container-2"
                            >
                                <span className="menu-text-2-main">WARDROBE</span>
                            </Link>

                            <Link 
                            to={"/client/" + params.id + "/casting"}
                            className="menu-text-container-2"
                            >
                                <span className="menu-text-2-main">CASTING</span>
                            </Link>
                        </div>

                    
                </div>
            </div>
        </div>
        </>
    )
}