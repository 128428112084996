import { useEffect } from "react"


// ROUTER
import { useNavigate, Link, useParams } from 'react-router-dom';


export default function NeedLogin() {

    const navigate = useNavigate();

    
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/")

        }, 2000)


        return() => {
            clearTimeout(timer)
        }
    }, [])
    

    return(
        <>
            <div style={{color: "white"}}><p>YOU HAVE TO LOGIN TO RIGHT ACCOUNT TO SEE THIS DATA</p></div>
        </>
    )
}