
import { useEffect, useState } from "react";

// ROUTER
import { Link, useParams } from 'react-router-dom';

// PICTURES
import BackroundPic from "../pictures/locations.webp";
import Listapic from "../pictures/lista.png";

// HELPING FUNCTIONS
import { loadAllImages, getHeight } from "../functions/HelpingFunctions";

// COMPONENTS
import BackBtn from "./BackBtn";

export default function LocationsContent({ thisData }) {

    // ROUTER
    const params = useParams();


    // STATES
    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [BackroundPic];


    const [fileTableHandler, setFileTableHandler] = useState({
        opened: false,
        link: ""
    })

    // SCALING
    const [scale, setScale] = useState(1);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale()
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])


    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {
        async function loadImages() {
            try{
                const loadingState =  await loadAllImages(imageUrls);
                setAllImagesLoaded(loadingState);
            }
            catch(err) {
                console.log(err)
            }
        }
        loadImages()
        
    // eslint-disable-next-line
    }, []);


    return(
        <>
        <div 
        style={{
            opacity: allImagesLoaded,
            transition: "1s",
        }}
        >          
            <div className="flex-col align-center">
                <div 
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}
                className="background-pic flex-col align-center">
                    <img width={2311.5} height={1300} src={BackroundPic}></img>
                </div>


                <div className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>
                    <BackBtn />
                    
                    <div
                    style={{
                        transform: "translate(-550px, 420px)",
                        position: "absolute", 
                        
                    }}
                    >
                        <img style={{scale: "0.9"}} src={Listapic}></img>
                    </div>

                    <div
                    style={{
                        transform: "translate(-552px, 418px)",
                        position: "absolute", 
                        width: "500px",
                        textAlign: "center"
                    }}
                    >
                        <span className="menu-text-2">*{thisData?.nazev}</span>
                    </div>

                    <Link 
                        to={"/client/" + params.id + "/locations/archive-1"}

                        style={{
                            transform: "translate(-770px, -330px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                            <span className="locations-menu-text-2">ARCHIVE</span>
                            <span className="locations-menu-text-2">I.</span>
                    </Link>


                    <Link 
                        to={"/client/" + params.id + "/locations/archive-2"}

                        style={{
                            transform: "translate(-70px, -330px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                        <span className="locations-menu-text-2">ARCHIVE</span>
                        <span className="locations-menu-text-2">II.</span>
                    </Link>

                    
                    <Link 
                        to={"/client/" + params.id + "/locations/archive-3"}

                        style={{
                            transform: "translate(485px, -330px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                        <span className="locations-menu-text-2">ARCHIVE</span>
                        <span className="locations-menu-text-2">III.</span>
                    </Link>


                    <Link 
                        to={"/client/" + params.id + "/locations/scout-1"}

                        style={{
                            transform: "translate(-755px, 220px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                        <span className="locations-menu-text-2">SCOUT</span>
                        <span className="locations-menu-text-2">I.</span>
                    </Link>

                    <Link 
                        to={"/client/" + params.id + "/locations/scout-2"}

                        style={{
                            transform: "translate(-85px, 220px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                        <span className="locations-menu-text-2">SCOUT</span>
                        <span className="locations-menu-text-2">II.</span>
                    </Link>


                    <Link 
                        to={"/client/" + params.id + "/locations/scout-3"}

                        style={{
                            transform: "translate(162px, 220px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                        <span className="locations-menu-text-2">SCOUT</span>
                        <span className="locations-menu-text-2">III.</span>
                    </Link>


                    <Link 
                        to={"/client/" + params.id + "/locations/select"}

                        style={{
                            transform: "translate(505px, 200px)",
                            position: "absolute", 
                        }}
                        className="locations-text-container-2"
                        >
                            <span className="locations-menu-text-2">SELECT</span>
                    </Link>

                    
                    <div className="locations-container" >
                        <Link 
                        to={"/client/" + params.id + "/wardrobe"}
                        className="locations-text-container"
                        >
                            <span className="locations-menu-text">WARDROBE</span>
                        </Link>

                        <Link 
                        to={"/client/" + params.id + "/production-design"}
                        className="locations-text-container"
                        >
                            <span className="locations-menu-text">PROD. DESIGN</span>
                        </Link>

                        <Link 
                        to={"/client/" + params.id + "/casting"}
                        className="locations-text-container"
                        >
                            <span className="locations-menu-text">CASTING</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}