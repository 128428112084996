
// ROUTER
import { useParams } from 'react-router-dom';


// ROUTER
import LocationsPage from './LocationsPage';

// COMPONENTS

export default function LocationPage() {


    // ROUTER
    const params = useParams()
  
    return(
        <>
            {params.location === "locations"
            ? <LocationsPage />

            : null
            }


        </>
    )
}