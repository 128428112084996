import { useContext, useState } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// ROUTER
import { useParams } from 'react-router-dom';

// COMPONENTS
import FirebaseData from "../components/FirebaseData";
import NeedLogin from "../components/NeedLogin";
import LogOutBtn from "./LogOutBtn";
import LogOutBtnAdmin from "./LogOutBtnAdmin";


export default function AdminPageNew() {

    const [refresh, setRefresh] = useState(true);

    // ROUTER
    const params = useParams()

    // GLOBAL STATES
    const { logedIn } = useContext(DataContext);


    return(
        <>
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "50px",
            background: "rgb(15, 18, 20)"
        }}
        >
            <LogOutBtnAdmin />

            <div style={{marginTop: "110px"}}>
                {logedIn.login === params.id && logedIn.loggedIn === true
                ? <FirebaseData refresh={refresh} setRefresh={setRefresh}/>
                : null
                }           


                {logedIn.login != params.id || logedIn.loggedIn === false
                ? <NeedLogin />
                : null
                }   
            </div>

        </div>
        </>
    )
}