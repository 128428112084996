
import { useContext } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// ROUTER
import { useParams } from 'react-router-dom';

// COMPONENTS
import ClientContent from "../components/ClientContent";
import NeedLogin from "../components/NeedLogin";

export default function Cleintpage() {

    // ROUTER
    const params = useParams()

    // GLOBAL STATES
    const { logedIn } = useContext(DataContext);

    return(
        <>
            {logedIn.login === params.id && logedIn.loggedIn === true
            ? <ClientContent />
            : null
            }           


            {logedIn.login != params.id || logedIn.loggedIn === false
            ? <NeedLogin />
            : null
            }   
        </>
    )
}