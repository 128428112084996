

import { initializeApp } from "firebase/app";

//funkce na login
import { getAuth, GoogleAuthProvider } from "firebase/auth";

//funkce pro databazi
import{ getFirestore } from "firebase/firestore";

//funkce pro storage
import{ getStorage } from "firebase/storage";

// API KEYS
import { apiKeys } from "./apiKeys";


const firebaseConfig = {
    apiKey: apiKeys.firebaseApiKey,
    authDomain: "comfort-films.firebaseapp.com",
    projectId: "comfort-films",
    storageBucket: "comfort-films.appspot.com",
    messagingSenderId: "721426083863",
    appId: "1:721426083863:web:71458117039c72c25a2c52",
    measurementId: apiKeys.googleId
};

//firebase init
const app = initializeApp(firebaseConfig);

//login var
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

//var pro databazi
export const db = getFirestore(app);

//var pro storage
export const storage = getStorage(app);