
import { useEffect, useState } from "react";

// FIREBASE
import { db } from '../config/firebase';
import { getDocs, collection } from "firebase/firestore";

// ROUTER
import { useParams } from 'react-router-dom';

// COMPONENTS
import MenuV1 from "./MenuV1";
import MenuV2 from "./MenuV2";
import MenuV3 from "./MenuV3";
import MenuV4 from "./MenuV4";
import MenuV5 from "./MenuV5";
import AdminPageNew from "./AdminPageNew";

export default function ClientContent() {

    // ROUTER
    const params = useParams()

    // STATES
    const [thisData, setThisData] = useState(null);
    
    // SHOW CONTENT FOR CLIENT
    useEffect(() => {
        // GET ALL DATA FROM DATABASE
        async function getData(thisId) {
            try{
                const contentCollectionRef = collection(db, "data");
                const res = await getDocs(contentCollectionRef);
            
                // RELEASES
                const filteredData = res.docs.map((doc) => ({
                    ...doc.data(), 
                    id: doc.id, 
                }));
                
                const dataForUser = filteredData.filter(item => (item.login === thisId))
                setThisData(dataForUser[0])
            }catch(err) {
                console.log(err);
            }
        }

        getData(params.id)
    }, [])    

    return(
        <>
            {thisData?.variant === "1"
            ? <MenuV1 thisData={thisData}/>
            : null
            }
            
            {thisData?.variant === "2"
            ? <MenuV2 thisData={thisData}/>
            : null
            }

            {thisData?.variant === "3"
            ? <MenuV3 thisData={thisData}/>
            : null
            }

            {thisData?.variant === "4"
            ? <MenuV4 thisData={thisData}/>
            : null
            }

            {thisData?.variant === "5"
            ? <MenuV5 thisData={thisData}/>
            : null
            }

            {thisData?.variant === "admin"
            ? <AdminPageNew />
            : null
            }
            
        </>
    )
}