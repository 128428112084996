import { useContext } from "react";

// GLOBAL STATES
import DataContext from "../globalstates/DataContext";

// ROUTER
import { useNavigate } from 'react-router-dom';

// IMAGE
import logoutIcon from '../pictures/icons/logout.svg';

import { defaultLogedIn } from "../App";

export default function LogOutBtnAdmin() {

    // ROUTER
    const navigate = useNavigate()
    
    // GLOBAL STATES
    const { setLoggedIn } = useContext(DataContext);

    // LOGOUT
    function logOut() {
        setLoggedIn(defaultLogedIn);
        navigate("/");
    }

    return(

        <div className="logoutbtn-container-admin">
            <button 
            className="p-1"
            onClick={logOut}
            >
                <img width="50px" height="auto" src={logoutIcon}/>
            </button>
        </div>
    )
}