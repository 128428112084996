
import { useContext } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// ROUTER
import { useParams } from 'react-router-dom';

// COMPONENTS
import FileTableContent from "../components/FileTableContent";
import NeedLogin from "../components/NeedLogin";


export default function FileTablePage() {

    // ROUTER
    const params = useParams()

    // GLOBAL STATES
    const { logedIn, thisData } = useContext(DataContext);


    return(
        <>
            {logedIn.login === params.id && logedIn.loggedIn === true
            ? <FileTableContent thisData={thisData}/>
            : null
            }           


            {logedIn.login != params.id || logedIn.loggedIn === false
            ? <NeedLogin />
            : null
            }   
        </>
    )
}