import { useEffect, useState } from 'react';

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// FIREBASE DATABASE
import { db } from '../config/firebase';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import { FormControl } from '@mui/material';


export default function FirebaseData({ refresh, setRefresh }) {

    const [thisData, setThisData] = useState([]);
    const [saveHandler, setSaveHandler] = useState([]);


    //UPDATE RELEASE DATABASE
    const updateDatabase = async (updateDataId, databaseUpdateObj) => {
        try {
            await updateDoc(doc(db, "data", updateDataId), databaseUpdateObj);
            setRefresh(!refresh);

        } catch(err) {
        console.error(err);
        }
        
    };


    useEffect(() => {
        // GET ALL DATA FROM DATABASE
        async function getData() {
            try{
                const contentCollectionRef = collection(db, "data");
                const res = await getDocs(contentCollectionRef);
            
                // RELEASES
                const filteredData = res.docs.map((doc) => ({
                    ...doc.data(), 
                    id: doc.id, 
                }));
                
                // sort by date
                const sortedData = filteredData.sort((a, b) => a.created - b.created);
                const noAdminData = sortedData.filter(i => (i.login !== "admin"))

                setThisData(noAdminData)
                setSaveHandler(noAdminData.map(i => ({ index: i.index, changed: false })));
                //setResultData(votedTracksSumSortIndexed);
            }catch(err) {
                console.log(err);
            }
        }

        getData()
    }, [refresh])

    return(
        <>
        <div className='flex-col'>
            {thisData.map((item, index) => {

                const thisIndex = item.index
                
                return(
                    <div 
                    key={index} 
                    className='flex-col gap-2'
                    style={{
                        //background: "rgba(200,200,200, 1)",
                        background: "rgba(220, 220, 220, 1)",
                        marginBottom: "20px",
                        //width: "600px",
                        //width: "100%",
                        padding: "20px"
                    }}
                    >
                        <p>{index + 1}</p>

                        <TextField 
                            id="outlined-basic" 
                            label="Project name" 
                            variant="outlined" 
    
                            //disabled={true}
                            value={item.nazev} 
                            onChange={(e) => {
                                // change data input
                                setThisData(prevData =>
                                    prevData.map(i =>
                                      i.index === thisIndex ? { ...i, nazev: e.target.value } : i
                                    )
                                );

                                // change was made
                                setSaveHandler(prevData =>
                                    prevData.map(i =>
                                      i.index === thisIndex ? { ...i, changed: true } : i
                                    )
                                );
                            }}
                        />

                        <div
                        className='flex-row gap-1'
                        >
                            <TextField 
                                id="outlined-basic" 
                                label="Login" 
                                variant="outlined" 
                                //disabled={true}
                                value={item.login} 
                                onChange={(e) => {
                                    // change data input
                                    setThisData(prevData =>
                                        prevData.map(i =>
                                        i.index === thisIndex ? { ...i, login: e.target.value.toLowerCase() } : i
                                        )
                                    );

                                    // change was made
                                    setSaveHandler(prevData =>
                                        prevData.map(i =>
                                        i.index === thisIndex ? { ...i, changed: true } : i
                                        )
                                    );
                                }}
                            />

                            <TextField 
                                id="outlined-basic" 
                                label="Password" 
                                variant="outlined" 
                                //disabled={true}
                                value={item.heslo} 
                                onChange={(e) => {
                                    // change data input
                                    setThisData(prevData =>
                                        prevData.map(i =>
                                        i.index === thisIndex ? { ...i, heslo: e.target.value.toLowerCase() } : i
                                        )
                                    );

                                    // change was made
                                    setSaveHandler(prevData =>
                                        prevData.map(i =>
                                        i.index === thisIndex ? { ...i, changed: true } : i
                                        )
                                    );
                                }}
                            />
                        </div>

                        <Button 
                        onClick={() => {
                            updateDatabase(item.id, item)
                        }}
                        variant="contained"
                        disabled={!saveHandler[index].changed}
                        >Save</Button>
                    </div>
                )
            })}
        </div>
        </>
    )
}