import React from 'react';
import {Checkbox, Skeleton} from "@mui/material";
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import {Favorite} from "@mui/icons-material";

export default function File({name, icon, type, onClick, path, previewBase64 = null, loading = false, onLike, isLiked}) {
    const label = { inputProps: { 'aria-label': 'Like item' } };

    return (
        <>
            {type === 'folder' && (
                <div className="file" onClick={() => onClick(path)}
                     style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <img className="file-icon" src={icon} alt={`${type} icon`}/>
                    <span className="file-name">{name}</span>
                </div>
            )}
            {type === 'file' && (
                <div className="file"
                     style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <div className="file-save-checkbox">
                        <Checkbox
                            {...label}
                            icon={<FavoriteBorder />}
                            size={"large"}
                            color={"secondary"}
                            checkedIcon={<Favorite />}
                            checked={isLiked || false}
                            onChange={onLike}
                        />
                    </div>
                    {previewBase64 &&
                        <img  className="file-image" src={`data:image/*;base64,${previewBase64}`} alt={`${type} icon`} onClick={() => onClick(path)} />
                    }

                    {loading && <div className="file-loading"></div>}
                    {!previewBase64 &&
                        <div style={{height: 128, width: 128, margin: "auto"}}>
                            <Skeleton variant="rounded" width={110} height={110} />
                        </div>
                    }
                    <span className="file-name">{name}</span>
                </div>
            )}
        </>
    );
}