import React, {useEffect, useState} from 'react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import folderIcon from './icons/folder.png';
import prevIcon from './icons/prev.png';
import './File.css';
import File from "./File";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Checkbox, IconButton, Skeleton, Snackbar, Tooltip} from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import {Check, Favorite, Share} from "@mui/icons-material";
import useLocalStorageState from "use-local-storage-state";
import {defaultLogedIn} from "../../App";
import { Download as DownloadIcon } from '@mui/icons-material';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import CircularProgressWithLabel from "./CircularProgressWithLabel";

export default function FileBrowser({basePath, setNavigation}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(new Map());
    const [error, setError] = useState(false);
    const [openSlider, setOpenSlider] = useState(false);
    const [index, setIndex] = useState(0);

    const [currentPath, setCurrentPath] = useState(basePath);
    const [likes, setLikes] = useState(new Map());

    const [copySuccess, setCopySuccess] = useState(false);
    const [logedIn] = useLocalStorageState('logedIn', {defaultValue: defaultLogedIn});
    const [shareItem, setShareItem] = useState(undefined);
    const [downloadingItems, setDownloadingItems] = useState({});

    const handleCloseCopySuccess = () => {
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 5000);
    }

    const updateData = (k, v) => {
        if (data.get(k)) {
            data.set(k, v);
            setData(data);
        }
    };

    const updateLike = (k, v) => {
        likes.set(k, v);
        setLikes(likes);
    };

    const setFileLike = async (filePath, like) => {
        try {
            const response = await fetch('https://setfilelike-vgjwhs6wxq-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ path: filePath, like: like })
            });
            if (!response.ok) {
                throw new Error('Failed to set file like');
            }
            setLikes(likes.set(filePath, like));
        } catch (error) {
        }
    };

    const loadLikes = async () => {
        try {
            const response = await fetch('https://loadlikes-vgjwhs6wxq-uc.a.run.app');
            if (!response.ok) {
                throw new Error('Failed to load likes');
            }
            const result = await response.json();
            const likesMap = new Map();
            Object.keys(result).forEach(function(key) {
                likesMap.set(key, result[key]);
            });
            setLikes(likesMap);

        } catch (error) {
            console.error('Error loading likes:', error);
        }
    };



    useEffect( () => {
        setData(new Map());
        setLoading(true)
        let share = undefined;
        let shareLowerPath = undefined;
        share = atob((new URL(window.location)).searchParams.get('share'))?.split(':');
        if (share[0]) {
            shareLowerPath = share[0];
            share = share[0].split('/').slice(0, -1).join('/');
            if (share && share.length > 0) {
                setCurrentPath(share);
            }
        }

        const path = currentPath || basePath;

        // no store on upper level, so this dummy solution, im sorry
        if (path === undefined) {
            return;
        }

        fetch(`https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${path}`)
            .then(response => response.json())
            .then(newData => {
                setData(new Map(newData.map(entry => [entry.name, entry])));
                setLoading(false);
                if (shareLowerPath) {
                    const shareItem = newData.find(entry => entry.path_lower === shareLowerPath);
                    if (shareItem) {
                        setShareItem(shareItem);
                    }
                }
                newData.forEach(async entry => {
                    if (entry['.tag'] === 'file') {
                        entry.fullUrl = (await loadFullUrl(entry.path_lower));
                       // updateData(entry.name, entry);
                    }
                });

            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(true);
            });

         loadLikes();
    }, [currentPath, basePath]);


    useEffect(() => {
        if (!basePath || !currentPath) {
            return;
        }
        const copy = currentPath.toLowerCase();
        const items = copy.replace(basePath.toLowerCase(), '').split('/').filter(i => i.length > 0);
        setNavigation([
            items.map(
                (item, index) => {
                    return <span> / <span key={item} className={"navigation-item"} onClick={() => {
                        setCurrentPath(basePath + '/' + items.slice(0, index + 1).join('/'));
                    }}> {item.toUpperCase()}</span></span>;
                }
            )
        ]);
    }, [currentPath]);


    const handleFolderClick = (path) => {
        setCurrentPath(path);
    };

    const goBack = () => {
        if (basePath.length === currentPath.length) {
            return;
        }
        setCurrentPath(currentPath.split('/').slice(0, -1).join('/'));
    }

    const canGoBack = currentPath !== basePath;


    const loadFullUrl = async (path) => {
        try {
            const response = await fetch(`https://loadfullurl-vgjwhs6wxq-uc.a.run.app?path=${encodeURIComponent(path)}`);
            if (!response.ok) {
                throw new Error('Failed to load full URL');
            }

            const data = await response.json();
            return data.link;
        } catch (error) {
            console.error('Error loading full URL:', error);
            throw error;
        }
    };

    const assetLink = (asset, width) => {
        return asset;
    }

    const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];

    const slides = Array.from(data?.values()).filter(entry => entry['.tag'] === 'file' && entry.name.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/)).map(entry => ({
        id: entry.name,
        title: entry.name,
        asset: entry.fullUrl,
        width: 3840, height: 2560
    }))
        .map(({asset, width, height, id}) => ({
            id: id,
            src: assetLink(asset, width),
            width,
            height,
            srcSet: breakpoints.map((breakpoint) => ({
                src: assetLink(asset, breakpoint),
                width: breakpoint,
                height: Math.round((height / width) * breakpoint),
            })),
        }));

    const videoSlides = Array.from(data?.values()).filter(entry => entry['.tag'] === 'file' && entry.name.toLowerCase().match(/\.(mp4|mov|avi)$/))
        .map(entry => ({
            id: entry.name,
            src: entry.fullUrl,
            type: "video",
            width: 1280,
            height: 720,
            poster: `data:image/*;base64,${entry.thumbnail}`,
            sources: [
                {
                    src:
                    entry.fullUrl,
                    type: "video/mp4"
                }
            ]
        }));

    slides.push(...videoSlides);

    if (shareItem) {
        loadFullUrl(shareItem.path_lower)
            .then(() => {
                shareItem.loading = false;
                shareItem.fullUrl = shareItem.fullUrl || '';
                updateData(shareItem.name, shareItem);
                setIndex(slides.findIndex(slide => slide.id === shareItem.name));
                setOpenSlider(true);
                setShareItem(null);
                const url = new URL(window.location);
                url.searchParams.delete("share");
                window.history.pushState('page2', 'Title', url.toString());
            })
    }

    const toggleLike = (fileName) => {
        const file = data.get(fileName);
        const currentLike = likes.get(file.path_lower);
        const newLike = !currentLike;

        // Update the likes state
        setLikes(new Map(likes.set(file.path_lower, newLike)));

        // Update the file data
        file.liked = newLike;
        updateData(fileName, file);

        // Call the API to set the like
        setFileLike(file.path_lower, newLike);
    };

    if (error) {
        return <div className="loader-container" style={{color: "white", fontSize: 24}}>Sorry! Something went wrong... Please try again later</div>;
    }

    if (loading) {
        return <div className='file-browser'>
            <div className="file-browser-grid">
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
            </div>
        </div>;
    }


    const downloadPath = async (path, saveName, loadingId) => {
        const zip = new JSZip();

        // Helper function to download a file and add it to the ZIP
        const downloadFile = async (fileItem, zipFolder) => {
            try {
                if (!fileItem.fullUrl || fileItem.fullUrl === '') {
                    fileItem.fullUrl = await loadFullUrl(fileItem.path_lower); // Ensure full URL is resolved
                }

                const response = await fetch(fileItem.fullUrl);

                if (!response.ok) {
                    console.error(`Failed to fetch ${fileItem.name}: ${response.statusText}`);
                    return;
                }

                const blob = await response.blob();
                const arrayBuffer = await blob.arrayBuffer();

                const fileExtension = fileItem.name.split('.').pop().toLowerCase();
                if (['mp4', 'mov', 'avi'].includes(fileExtension)) {
                    zipFolder.file(fileItem.name, arrayBuffer, { binary: true });
                } else {
                    zipFolder.file(fileItem.name, arrayBuffer);
                }
                downloadingItems[loadingId].done++;
                setDownloadingItems(prevState => ({...prevState, [loadingId]: downloadingItems[loadingId]}));
            } catch (error) {
                console.error(`Error downloading file ${fileItem.name}:`, error);
            }
        };


        // Helper function to process items recursively
        const processItems = async (items, zipFolder) => {
            downloadingItems[loadingId].total += items.length;
            await Promise.all(
                items
                    .map(async (item) => {
                        if (item['.tag'] === 'folder') {
                            const newZipFolder = zipFolder.folder(item.name);
                            const filesInFolder = await (await fetch(`https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${item.path_lower}`)).json();
                            await processItems(filesInFolder, newZipFolder, item.path_lower); // Recursive for subfolders
                        } else {
                            await downloadFile(item, zipFolder);
                        }
                    })
            );
        };
        try {
            setDownloadingItems(prevState => ({...prevState, [loadingId]: {total: 1, done: 0}}));
            downloadingItems[loadingId] = {total: 1, done: 0};
            const response = await fetch(`https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${path}`);
            if (!response.ok) throw new Error(`Failed to fetch items: ${response.statusText}`);
            const items = await response.json();

            // Start processing items from the root folder
            await processItems(items, zip, path);

            const zipContent = await zip.generateAsync({ type: 'blob' });
            saveAs(zipContent, `${saveName}.zip`);
        } catch (error) {
            console.error('Error in downloadPath:', error);
        }

        setDownloadingItems(prevState => ({
            ...prevState,
            [loadingId]: false
        }));
    };

    return (
        <div>
            <div className="file-browser">
                {canGoBack && (
                    <div className="file" onClick={() => goBack()}
                         style={{cursor: 'pointer', display: 'flex', alignItems: 'center', width: 220, marginLeft: 4, marginBottom: 10}}>
                        <img className="file-icon" src={prevIcon} alt={`back icon`}/>
                        <span className="file-name">Back</span>
                    </div>
                )}
                <div className="file-browser-grid">
                    {Array.from(data?.values()).map(item => (
                        item['.tag'] === 'file' ? (
                            <File key={item.name} type={item['.tag']} name={item.name} icon={null} path={item.path_lower} previewBase64={item.thumbnail} loading={(!item.fullUrl && item.loading) || false}
                                  onClick={
                                      () => {
                                          if (item.name.toLowerCase().match(/\.(jpeg|jpg|png|gif|mp4|mov|avi)$/)) {
                                              if (!item.fullUrl || item.fullUrl === '') {
                                                  item.loading = true;
                                                  loadFullUrl(item.path_lower)
                                                      .then(() => {
                                                            item.loading = false;
                                                            item.fullUrl = item.fullUrl || '';
                                                            updateData(item.name, item);
                                                      })
                                              }
                                              setIndex(slides.findIndex(slide => slide.id === item.name));
                                              setOpenSlider(true);
                                          } else {
                                              window.open(item.fullUrl, '_blank');
                                          }
                                      }
                                  }
                                  onLike={() => toggleLike(item.name)}
                                  isLiked={likes.get(item.path_lower) || false}
                            >

                            </File>
                        ) : (
                            <div key={item.id} className="file" onClick={() => handleFolderClick(item.path_lower)}
                                 style={{
                                     cursor: 'pointer',
                                     display: 'flex',
                                     alignItems: 'center',
                                     position: 'relative'
                                 }}>
                                <img className="file-icon" src={folderIcon} alt={`${item['.tag']} icon`}/>
                                <span className="file-name">{item.name}</span>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: '10px',
                                    bottom: '10px',
                                }}
                                            onClick={async (e) => {
                                                e.stopPropagation(); // Prevent event propagation
                                                if (window.confirm('Do you want to download the folder?')) {
                                                    downloadPath(item.path_lower, item.name, item.id);
                                                }
                                            }}>
                                    {downloadingItems[item.id] && downloadingItems[item.id].hasOwnProperty('total') ? <CircularProgressWithLabel value={Math.round((downloadingItems[item.id].done/downloadingItems[item.id].total) * 100)} /> :
                                        <DownloadIcon fontSize={"large"} color={"secondary"}/>}
                                </IconButton>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <Lightbox
                open={openSlider}
                index={index}
                close={() => setOpenSlider(false)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom, Download]}
                slides={slides}
                on={{
                    view: (index) => {
                        setIndex(index.index);
                    }
                }}
                toolbar={{
                    buttons: [
                        <Checkbox
                            icon={<FavoriteBorder color={"secondary"}/>}
                            size={"large"}
                            color={"secondary"}
                            checkedIcon={<Favorite/>}
                            checked={index !== undefined && slides[index] && likes.get(data.get(slides[index].id)?.path_lower) || false}
                            onChange={index !== undefined && slides[index] ? () => toggleLike(slides[index].id) : null}
                        />,
                        <Tooltip title="Copy link">
                            <IconButton color="primary" aria-label="Copy link"
                                        onClick={() => {
                                            handleCloseCopySuccess();
                                            const url = new URL(window.location);
                                            url.searchParams.set("share", btoa(data.get(slides[index].id)?.path_lower + ":" + logedIn.login + ":" + logedIn.password));
                                            navigator.clipboard.writeText(url.toString());
                                        }}
                            >
                                {copySuccess ? <Check fontSize={"large"}></Check> : <Share fontSize={"large"}/>}
                            </IconButton>
                            {copySuccess &&
                                <div style={{color: "white", position: "absolute"}} className="copy-to-clipboard">Copied
                                    to clipboard!</div>}
                        </Tooltip>,
                        "close"
                    ]
                }}
            />
        </div>
    );
}