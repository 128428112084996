
// ROUTER
import { useNavigate, Link, useParams } from 'react-router-dom';

// IMAGES
import backImg from '../pictures/icons/back_final.png';

export default function BackBtn() {

    // ROUTER
    const params = useParams();

    return(

        <div className="backbtn-container">

            <Link
            to={"/client/" + params.id }
            >
                <button>
                    <img width="50px" height="auto" src={backImg}/>
                </button>
            </Link>
        </div>
    )
}