import { useContext, useEffect, useState } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// ROUTER
import { useNavigate } from 'react-router-dom';

// IMAGES
import loginImg from "../pictures/icons/login.svg";
import loadingImg from "../pictures/icons/loading.svg";

export default function LoginLandingPage({ scale }) {

    // ROUTER
    const navigate = useNavigate();

    // GLOBAL STATES
    const {setLoggedIn} = useContext(DataContext);

    // STATES
    const [login, setLogin] = useState({
        login: "",
        heslo: ""
    });
    const [loginErr, setLoginErr] = useState("");
    const [showLoginBtn, setShowLoginBtn] = useState({
        opacity: "0",
        cursor: "initial",
        pointerEvents: "none",
        loginOpacity: "0",
        loadingOpacity: "0", 
    }); 
    const [loadingHandler, setLoadingHandler] = useState(false);
    const [inputDisableHandler, setInputDisableHandler] = useState(false);

    const thisImg = loadingImg;


    // LOGIN BTN VISIBILITY VALIDATION
    function loginBtnVisibleCheck(login, heslo) {
        if(login.length > 0 && heslo.length > 0) {
            setShowLoginBtn({
                opacity: "1",
                cursor: "pointer",
                pointerEvents: "all",
                loginOpacity: "1",
                loadingOpacity: "0",
            });
        } else {
            setShowLoginBtn({
                opacity: "0",
                cursor: "initial",
                pointerEvents: "none",
                loginOpacity: "0",
                loadingOpacity: "0",
            });
        }
    }


    // VALIDATION
    function validation(login, heslo) {
        loginFunction(login, heslo)
    }


    // LOGIN FUNCTION
    async function loginFunction(login, heslo) {
        try {

            // when start loading (btn change)
            setLoginErr("");
            setInputDisableHandler(true);
            setShowLoginBtn({
                opacity: "1",
                cursor: "initial",
                pointerEvents: "none",
                loginOpacity: "0",
                loadingOpacity: "1",
            });

            const response = await fetch(`https://searchbyemailandheslo-vgjwhs6wxq-uc.a.run.app/?login=${login}&heslo=${heslo}`);
    
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
    
            const data = await response.text();

            // AFTER SUCCESSFULL LOGIN
            if(data === "true") {
                setLoggedIn({
                    login: login,
                    password: heslo,
                    loggedIn: true,
                });
                setLoginErr("");

                navigate("/client/" + login);
                
            } else{
                setLogin({
                    login: "",
                    heslo: ""
                })
                setLoginErr("*login or password is incorect");
            }


            setInputDisableHandler(false);
            setShowLoginBtn({
                opacity: "0",
                cursor: "initial",
                pointerEvents: "none",
                loginOpacity: "0",
                loadingOpacity: "0",
            });


        } catch (error) {
            console.error('Error searching for email:', error);

            setInputDisableHandler(false);
            setShowLoginBtn({
                opacity: "1",
                cursor: "pointer",
                pointerEvents: "all",
                loginOpacity: "1",
                loadingOpacity: "0",
            });

            setLoginErr("*check your internet connection");

        }
    }

    
    // LOGIN BTN VISIBILITY CHECK
    useEffect(() => {
        loginBtnVisibleCheck(login.login, login.heslo);
    }, [login.login, login.heslo])
    
    function loginOnEnter(e) {
        if(e.key === "Enter") {
            if(login.login.length > 0 && login.heslo.length > 0) {
                validation(login.login, login.heslo)
            }
        } 
    }

    // VALIDATION ON ENTER
    useEffect(() => {
        window.addEventListener("keydown", loginOnEnter);

        return() => {
            window.removeEventListener("keydown", loginOnEnter);
        }
    })

    return(
            <div
            className="flex-col"
            style={{
                height: "180px",
                background: "rgba(255, 0, 0, 0)",
                width: "290px",
                transform: "translate(-590px, 390px)",
                
            }}>
                
                <input disabled={inputDisableHandler} style={{fontFamily: "bebas_regular", letterSpacing: "8px", background: "rgba(193, 220, 247, 1)" ,height: "60px", fontSize: "30px", border: "none", outline: "none" , paddingLeft: "20px"}} 
                onChange={(e) => {
                    setLogin({...login, login: e.target.value.toLowerCase()});
                    setLoginErr("");
                }} 
                value={login.login} 
                placeholder="login" />
                
                <div style={{background: "rgba(193, 220, 247, 1)", marginTop: "14px", paddingLeft: "20px", paddingRight: "20px"}} className="flex-row">
                    <input disabled={inputDisableHandler} style={{width: "100%",fontFamily: "bebas_regular", letterSpacing: "8px", background: "transparent", height: "60px", fontSize: "30px", border: "none", outline: "none" , paddingLeft: "0px"}} 
                    onChange={(e) => {
                        setLogin({...login, heslo: e.target.value.toLowerCase()});
                        setLoginErr("");
                    }} 
                    value={login.heslo} 
                    placeholder="password" />

                    <button 
                    style={{
                        background: "transparent", 
                        border: "none", 
                        transition: "0.5s",
                        opacity: showLoginBtn.opacity,
                        pointerEvents: showLoginBtn.pointerEvents,
                        cursor: showLoginBtn.cursor
                    }} 
                    onClick={() => {validation(login.login, login.heslo)}}>
                            <div 
                            style={{
                                position: "relative"
                            }}
                            >
                            <img
                            style={{
                                opacity: showLoginBtn.loginOpacity
                            }}
                            width="45" height="auto" src={loginImg}></img>
                            <img 
                            style={{
                                position: "absolute",
                                left: "10px",
                                top: "5px",
                                opacity: showLoginBtn.loadingOpacity
                            }}
                            className="loadingImg" width="35" height="auto" src={thisImg}></img>
                            </div>
                    </button>
                </div>
                
            
                <p style={{marginTop: "12px", paddingLeft: "30px"}} className="error">{loginErr}</p>
            </div>

    )
}