
// ROUTER
import { useParams } from 'react-router-dom';
import FileTableNew from "./FileTableNew";
import LocationsContent from "./LocationsContent";
import ProductionDesContent from './ProductionDesContent';
import WardrobeContent from './WardrobeContent';
import CastingContent from './CastingContent';

export default function FileTableContent({ thisData }) {

    // ROUTER
    const params = useParams();
  
    return(
        <>
            {params.location === "locations" && params.folder === "archive-1" 
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations1} 
                    />
                </>
            )
            : null
            }

            {params.location === "locations" && params.folder === "archive-2"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations2} 
                    />
                </>
            )
            : null
            }

            {params.location === "locations" && params.folder === "archive-3"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations3} 
                    />
                </>
            )
            : null
            }   

            {params.location === "locations" && params.folder === "scout-1"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations4} 
                    />
                </>
            )
            : null
            }   

            {params.location === "locations" && params.folder === "scout-2"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations5} 
                    />
                </>
            )
            : null
            }   

            {params.location === "locations" && params.folder === "scout-3"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations6} 
                    />
                </>
            )
            : null
            } 

            {params.location === "locations" && params.folder === "select"
            ? (
                <>
                    <LocationsContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.locations7} 
                    />
                </>
            )
            : null
            }             


            {params.location === "production-design" && params.folder === "mood-1"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production1} 
                    />
                </>
            )
            : null
            }         

            {params.location === "production-design" && params.folder === "mood-2"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production2} 
                    />
                </>
            )
            : null
            }   

            {params.location === "production-design" && params.folder === "mood-revised"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production3} 
                    />
                </>
            )
            : null
            } 

            {params.location === "production-design" && params.folder === "actual-props"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production4} 
                    />
                </>
            )
            : null
            } 

            {params.location === "production-design" && params.folder === "design"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production5} 
                    />
                </>
            )
            : null
            } 
            
            {params.location === "production-design" && params.folder === "director-selection"
            ? (
                <>
                    <ProductionDesContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.production6} 
                    />
                </>
            )
            : null
            } 



            {params.location === "wardrobe" && params.folder === "mood"
            ? (
                <>
                    <WardrobeContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.wardobe1} 
                    />
                </>
            )
            : null
            } 

            {params.location === "wardrobe" && params.folder === "mood-revised"
            ? (
                <>
                    <WardrobeContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.wardobe2} 
                    />
                </>
            )
            : null
            } 

            {params.location === "wardrobe" && params.folder === "actual-wardrobe"
            ? (
                <>
                    <WardrobeContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.wardobe3} 
                    />
                </>
            )
            : null
            } 

            {params.location === "wardrobe" && params.folder === "directors-select"
            ? (
                <>
                    <WardrobeContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.wardobe4} 
                    />
                </>
            )
            : null
            } 


            {params.location === "casting" && params.folder === "1st-round"
            ? (
                <>
                    <CastingContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.casting1} 
                    />
                </>
            )
            : null
            } 

            {params.location === "casting" && params.folder === "2nd-round"
            ? (
                <>
                    <CastingContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.casting2} 
                    />
                </>
            )
            : null
            } 

            {params.location === "casting" && params.folder === "callback"
            ? (
                <>
                    <CastingContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.casting3} 
                    />
                </>
            )
            : null
            }    

            {params.location === "casting" && params.folder === "director-selection"
            ? (
                <>
                    <CastingContent thisData={thisData}/>
                    <FileTableNew 
                        projectName={thisData?.nazev} 
                        thisData={thisData?.casting4} 
                    />
                </>
            )
            : null
            }         
        </>
    )
}