
import { useEffect, useState } from "react";

// ROUTER
import { Link, useParams } from 'react-router-dom';

// PICTURES
import BackroundPic from "../pictures/casting.webp";
import Listapic from "../pictures/lista.png";

// HELPING FUNCTIONS
import { loadAllImages, getHeight } from "../functions/HelpingFunctions";

// COMPONENTS
import BackBtn from "./BackBtn";

export default function CastingContent({ thisData }) {

    // ROUTER
    const params = useParams()

    // GLOBAL STATES

    // STATES
    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [BackroundPic];

    // SCALING
    const [scale, setScale] = useState(1);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale();
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])

    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {
        async function loadImages() {
            try{
                const loadingState =  await loadAllImages(imageUrls);
                setAllImagesLoaded(loadingState);
            }
            catch(err) {
                console.log(err)
            }
        }
        loadImages()
        
        // eslint-disable-next-line
    }, []);

    return(
        <>
        <div 
        style={{
            opacity: allImagesLoaded,
            transition: "1s",
        }}
        >                                   
            <div className="flex-col align-center">
                <div 
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}
                className="background-pic flex-col align-center">
                    <img width={2311.5} height={1300} src={BackroundPic}></img>
                </div>

                <div className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>
                    <BackBtn />


                    <div
                    style={{
                        transform: "translate(-550px, 420px)",
                        position: "absolute", 
                        
                    }}
                    >
                        <img style={{scale: "0.9"}} src={Listapic}></img>
                    </div>

                    <div
                    style={{
                        transform: "translate(-552px, 418px)",
                        position: "absolute", 
                        width: "500px",
                        textAlign: "center"
                    }}
                    >
                        <span className="menu-text-2">*{thisData?.nazev}</span>
                    </div>

                    <div 
                    style={{                         
                        transform: "translate(425px, -25px)",
                        perspective: "580px",
                        perspectiveOrigin: "0% 95px",
                    }}>
                        <div 
                        className="casting-container"
                        style={{
                            transformStyle: "preserve-3d",                              
                            transform: "rotateY(-21deg)",
                        }}
                        >
                            <Link 
                                to={"/client/" + params.id + "/casting/1st-round"}
                                className="casting-text-container" 
                                >
                                    <span className="casting-text">1ST ROUND</span>
                            </Link>

                            <Link 
                                to={"/client/" + params.id + "/casting/2nd-round"}
                                className="casting-text-container" 
                                >
                                    <span className="casting-text">2ND ROUND</span>
                            </Link>
                            
                            <Link 
                                to={"/client/" + params.id + "/casting/callback"}
                                className="casting-text-container" 
                                >
                                    <span className="casting-text">CALLBACK</span>
                            </Link>

                            <Link 
                                to={"/client/" + params.id + "/casting/director-selection"}
                                className="casting-text-container" 
                                >
                                    <span className="casting-text">DIR. SELECTION</span>
                            </Link>
                        </div>
                    </div>

                    <div className="casting-container-2" >
                        <Link 
                        to={"/client/" + params.id + "/production-design"}
                        className="casting-text-container-2"
                        >
                            <span className="locations-menu-text">PROD. DESIGN</span>
                        </Link>

                        <Link 
                        to={"/client/" + params.id + "/wardrobe"}
                        className="casting-text-container-2"
                        >
                            <span className="locations-menu-text">WARDROBE</span>
                        </Link>

                        <Link 
                        to={"/client/" + params.id + "/locations"}
                        className="casting-text-container-2"
                        >
                            <span className="locations-menu-text">LOCATIONS</span>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}